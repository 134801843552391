import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Parser } from 'htmlparser2';
import { DomHandler } from 'domhandler';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import './App.css';

function App() {
  const [forms, setForms] = useState([]);
  const [originalHtml, setOriginalHtml] = useState('');
  const [updatedHtml, setUpdatedHtml] = useState('');
  const [status, setStatus] = useState('');
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [newAction, setNewAction] = useState('');

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result;
        setOriginalHtml(content);
        parseHtml(content);
      };
      reader.readAsText(acceptedFiles[0]);
    }
  };

  const parseHtml = (html) => {
    setStatus('Processing...');
    const handler = new DomHandler((error, dom) => {
      if (error) {
        setStatus('Error parsing file');
        return;
      }

      const formsList = [];
      const traverse = (node) => {
        if (node.type === 'tag' && node.name === 'form') {
          const action = node.attribs.action || '';
          const name = node.attribs.name || 'Unnamed Form';
          formsList.push({ action, name, node });
        }
        if (node.children) {
          node.children.forEach(traverse);
        }
      };

      traverse({ children: dom });
      setForms(formsList);
      setStatus(' File Uploaded ');
    });

    new Parser(handler, { lowerCaseTags: true, lowerCaseAttributeNames: true })
      .end(html);
  };

  const applyNewAction = () => {
    if (newAction) {
      const updatedForms = [...forms];
      const form = updatedForms[currentFormIndex];
      const oldAction = form.action;
      form.action = newAction;
      updateFormAction(form.node, newAction);
      setForms(updatedForms);
      updateHtml(updatedForms, oldAction, newAction);
      setStatus('Updating...');
      setStatus('Done');
    }
  };

  const updateFormAction = (formNode, newAction) => {
    formNode.attribs.action = newAction;
  };

  const updateHtml = (updatedForms, oldAction, newAction) => {
    const regex = new RegExp(`action="${oldAction}"`, 'g');
    const newHtml = originalHtml.replace(regex, `action="${newAction}"`);
    setUpdatedHtml(newHtml);
  };

  const downloadUpdatedFile = () => {
    const blob = new Blob([updatedHtml], { type: 'text/html' });
    FileSaver.saveAs(blob, 'updated_file.html');
  };

  const generateZip = async () => {
    const zip = new JSZip();
    zip.file('updated_file.html', updatedHtml);
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    FileSaver.saveAs(zipBlob, 'updated_forms.zip');
  };

  const handlePrevious = () => {
    setCurrentFormIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentFormIndex(prevIndex => Math.min(prevIndex + 1, forms.length - 1));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.html, .php',
    multiple: false,
  });

  return (
    <div className="App">
      <div className="container">
        <h1>Form Action Updater</h1>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop an HTML or PHP file here, or click to select one</p>
        </div>
        <div className="forms-list">
          <h2>Forms List</h2>
          {forms.length > 0 ? (
            <>
              <div className="form-navigation">
                <button onClick={handlePrevious} disabled={currentFormIndex === 0}>
                  Previous
                </button>
                <span>
                  Form {currentFormIndex + 1} of {forms.length}
                </span>
                <button onClick={handleNext} disabled={currentFormIndex === forms.length - 1}>
                  Next
                </button>
              </div>
              <div className="form-details">
                <h3>Current Form</h3>
                <p><strong>Name:</strong> {forms[currentFormIndex].name}</p>
                <p><strong>Action:</strong> {forms[currentFormIndex].action}</p>
                <input
                className='control'
                  type="text"
                  value={newAction}
                  onChange={(e) => setNewAction(e.target.value)}
                  placeholder="Enter new form action URL"
                />
                <button onClick={applyNewAction}>Replace Action</button>
              </div>
              {updatedHtml && (
                <div className="updated-html">
                  <h3>Updated HTML Content:</h3>
                  <textarea
                    className="html-preview"
                    readOnly
                    value={updatedHtml}
                  />
                  <button onClick={downloadUpdatedFile}>Download Updated HTML</button>
                  <button onClick={generateZip}>Download as ZIP</button>
                </div>
              )}
            </>
          ) : (
            <p>No forms available. Please upload a file.</p>
          )}
        </div>
        <div className="status-box">
          <h2>Status</h2>
          <p>{status}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
